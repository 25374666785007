@import "halfmoon/css/halfmoon-variables.min.css";

:root {
  --primary-color: #e58900;                                     /* Before: var(--yellow-color) */
  
  --primary-color-light: #f2a532;                         /* Before: var(--yellow-color-light) */
  --primary-color-very-light: #f2bf74;               /* Before: var(--yellow-color-very-light) */

  --primary-color-dark: #e86d02;                           /* Before: var(--yellow-color-dark) */
  --primary-color-very-dark: #f97502;                 /* Before: var(--yellow-color-very-dark) */

  --primary-box-shadow-color: var(--orange-box-shadow-color);               /* Before: var(--yellow-box-shadow-color) */
  --primary-box-shadow-color-darker: var(--orange-box-shadow-color-darker); /* Before: var(--yellow-box-shadow-color-darker) */
  
  --text-color-on-primary-color-bg: #ffffffff;   /* Before: var(--text-color-on-yellow-color-bg) */

  /* --------------------------------------------------- */

  --secondary-color: #674ea7ff;                                     /* Before: var(--yellow-color) */
  
  --secondary-color-light: #856bc6ff;                         /* Before: var(--yellow-color-light) */
  --secondary-color-very-light: #baa9e5ff;               /* Before: var(--yellow-color-very-light) */

  --secondary-color-dark: #50339bff;                           /* Before: var(--yellow-color-dark) */
  --secondary-color-very-dark: #3a1696ff;                 /* Before: var(--yellow-color-very-dark) */

  --secondary-box-shadow-color: var(--purple-box-shadow-color);               /* Before: var(--yellow-box-shadow-color) */
  --secondary-box-shadow-color-darker: var(--purple-box-shadow-color-darker); /* Before: var(--yellow-box-shadow-color-darker) */
  
  --text-color-on-secondary-color-bg: var(--primary-color);   /* Before: var(--text-color-on-yellow-color-bg) */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
